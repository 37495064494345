import ObserverBase from 'fast-observer'


export enum ObserverEvents {
  LoginRequired = 'login-required',
  LogoutRequested = 'logout-requested',
  ServerConnectionError = 'server-connection-error',
  ModalDialogActivated = "ModalDialogActivated",
  ModalDialogDesactivated = 'modal-dialog-desactivated',
}

export class Observer extends ObserverBase {
  private static _instance: Observer

  public static get Instance(): Observer {
    return this._instance || (this._instance = new this())
  }
}