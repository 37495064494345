<template>
  <router-view />
  <modal-confirmation />
</template>

<script lang="ts">

import ModalConfirmation from '@/components/ModalConfirmation.vue'
import { Options, Vue } from 'vue-class-component'
import { Observer, ObserverEvents } from './models/Observer'

@Options({
  components: {
    ModalConfirmation,
  },
})
export default class App extends Vue {
  public beforeCreate(): void {
    Observer.Instance.subscribe(ObserverEvents.LoginRequired, () => {
      this.$router.push({ name: 'Login' })
    })
  }
}
</script>

<style lang="scss">
@import "assets/styles/style.scss";
</style>